import React from 'react';
import logoImage from "../../assets/images/logohome.webp"
import '../Welcomme/welcomme.css'
import { Link } from 'react-router-dom';

const DeveloperPresentation = () => {
  return (
    <div className='Welcome-main'>
      <div className='Welcome-container'>
        <img className='Welcome-img' src={logoImage} alt="Votre nom"/>
        <div className='Welcome-text'>
          <h1 className='Welcome-title'>Bienvenue, je suis Benoît</h1>
          <h2 className='Welcome-mention'>Développeur Web</h2>
          <h2 className='Welcome-about'>Mon objectif est de créer des expériences numériques de qualité en fournissant des solutions innovantes et efficaces. Je suis déterminé à contribuer à des projets significatifs en combinant créativité et compétences techniques. Travaillons ensemble pour atteindre vos objectifs.</h2>
          {/* Lien vers la page de contact */}
          <Link to="/contact">
            <button className="Welcome-button">Contactez-moi</button>
          </Link>
        </div>
      </div> 
    </div>
  );
};

export default DeveloperPresentation;
