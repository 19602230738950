import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Mention from './components/Mention';
import HomePage from './components/HomePage';
import NotFoundPage from './components/NotFoundPage';
import Politique from './components/Politique';
import Projet from './components/Projet';
import Contact from './components/Contact';
function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/Mention" element={<Mention />} />
        <Route exact path="/Politique" element={<Politique />} />
        <Route exact path="/Projet" element={<Projet />} />
        <Route exact path="/Contact" element={<Contact />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}

export default App;
