import React from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import '../index.css';
import Form from '../components/Form/Form';

function Contact() {
  return (
    <>
      <Header />
      <Form/>
     
      
      
      <Footer />
    </>
  );
}

export default Contact;
