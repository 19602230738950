import React from 'react';

const MentionsLegales = () => {
  return (
    <div className='Mentioncolor'>
      <h2>Mentions Légales</h2>

      <h3>Informations Légales :</h3>
      <p>Conformément aux dispositions de l'article 6-III-1 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique (LCEN), les présentes mentions légales sont mises à disposition des utilisateurs du site Benoitdx.com.</p>

      <h3>Propriétaire du Site :</h3>
      <ul>
        <li>Nom : BenoitDx - BERCHE Benoit</li>
        <li>Siren : 831354451 RCS EI</li>
        <li>Adresse : 80 rue Marie Curie, 62118 Biach St Vaast</li>
        <li>Email : contact@benoitdx.com</li>
        <li>Téléphone : 07 87 46 31 96</li>
      </ul>

      <h3>Hébergeur :</h3>
      <ul>
        <li>Nom : O2switch</li>
        <li>Adresse : Chemin des Pardiaux, 63000 Clermont-Ferrand</li>
      </ul>

      <h3>Propriété Intellectuelle et Contrefaçon :</h3>
      <p>Tous les contenus présents sur ce site (textes, images, vidéos, etc.) sont protégés par le droit d'auteur et/ou tout autre droit de propriété intellectuelle. Ils sont la propriété exclusive de BenoitDx - BERCHE Benoit. Toute reproduction, représentation, modification, publication, transmission ou dénaturation, intégrale ou partielle, du site ou de son contenu, par quelque procédé que ce soit, et sur quelque support que ce soit, est interdite, sauf autorisation expresse et préalable de BenoitDx - BERCHE Benoit. Toutes exploitations non autorisées seront poursuivies conformément aux dispositions des articles L-335-2 du code de propriété intellectuelle.</p>

      <h3>Limitations de Responsabilité :</h3>
      <p>BenoitDx - BERCHE Benoit décline toute responsabilité en cas de dommages directs ou indirects résultant de l'utilisation de ce site. L'utilisateur reconnaît utiliser ce site sous sa propre responsabilité.</p>

      <h3>Sécurité du Site et Contenu Inapproprié :</h3>
      <p>BenoitDx - BERCHE Benoit s'engage à sécuriser le site et à supprimer tout contenu inapproprié dès qu'il en aura connaissance.</p>

      <h3>Protection des Données Personnelles :</h3>
      <p>Conformément à la réglementation européenne, BenoitDx - BERCHE Benoit s'engage à protéger les données personnelles des utilisateurs. Pour plus d'informations sur la collecte et le traitement des données, veuillez consulter notre politique de confidentialité. Vous pouvez nous contacter pour la Protection des Données (DPO) à l'adresse contact@benoitdx.com pour exercer vos droits relatifs à vos données personnelles.</p>

      <h3>Liens Hypertextes et Cookies :</h3>
      <p>Un "cookie" (ou "témoin de connexion") est un petit fichier texte envoyé par un serveur web au navigateur de l'utilisateur lorsqu'il visite un site web. Ce fichier est ensuite stocké sur l'ordinateur de l'utilisateur. Ce site peut contenir des liens vers d'autres sites internet. BenoitDx - BERCHE Benoit ne peut être tenu pour responsable du contenu de ces sites tiers ni des éventuels cookies qu'ils pourraient déposer sur votre terminal. Conformément à la réglementation européenne, ce site ne collecte pas de cookies sans votre consentement préalable. Actuellement, ce site n'utilise pas de cookies.</p>

      <h3>Droit Applicable et Attribution de Juridiction :</h3>
      <p>Les présentes mentions légales sont soumises au droit français. En cas de litige, les tribunaux français seront seuls compétents.</p>
    </div>
  );
}

export default MentionsLegales;