import React from 'react';
import '../Footer/footer.css'
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
      <p>&copy; {new Date().getFullYear()} <a className='hyper-lien' href="mailto:contact@benoitdx.com">BenoîtDx</a>. Tous droits réservés.  <Link className='hyper-lien' to="/Mention">Mentions légales</Link> <Link className='hyper-lien' to="/Politique">Politique de confidentialité</Link></p>
      
      </div>
    </footer>
  );
};

export default Footer;
