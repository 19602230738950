import React from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import Mentions from '../components/Mention/Mention';
import '../index.css';

function Mention() {
  return (
    <>
      <Header />
      <Mentions/>
      
     
      
      
      <Footer />
    </>
  );
}

export default Mention;
