import React, { useState } from 'react';
import axios from 'axios'; // Importez axios

import './form.css';

function ContactForm() {
  const [formData, setFormData] = useState({
    name: '',
    sujet: '',
    email: '',
    message: ''
  });

  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => { 
    e.preventDefault();
    
    try {
      
      await axios.post('/send-email', formData); 
      console.log('Email envoyé avec succès!');
      setFormData({
        name: '',
        sujet: '',
        email: '',
        message: ''
      });
      setSubmitted(true);
    } catch (error) {
      console.error('Une erreur est survenue lors de l\'envoi de l\'e-mail:', error);
    }
  };

  return (
    <div className="container">
      <div className='title-form-main'>
        <i className="fa-solid fa-clipboard-question"></i>
        <p className='title-form'>Besoin d'aide pour votre projet ?</p>
        <p className='description-form'>N'hésitez pas à me joindre pour toute question, conseil ou demande de devis. Remplissez simplement le formulaire ci-dessous et je vous répondrai dans les meilleurs délais.</p>
        <p className='add-form'>Vous pouvez également me contacter directement par e-mail ou par téléphone du lundi au vendredi, de 9h à 18h.</p>
        <p className='infocall'><i className="fa-solid fa-phone"></i> 07.87.46.31.96</p>
        <p className='infocall'><i className="fa-solid fa-envelope"></i> contact@benoitdx.com</p>
      </div>
      {submitted ? (
        <p className="success-message">Votre demande a bien été prise en compte. Je vous recontacterai dans les plus brefs délais.</p>
      ) : (
        <form onSubmit={handleSubmit}>
          <label htmlFor="name">Nom & prénom</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Votre nom et prénom"
            value={formData.name}
            onChange={handleChange}
            required
          />

          <label htmlFor="sujet">Sujet</label>
          <input
            type="text"
            id="sujet"
            name="sujet"
            placeholder="L'objet de votre message"
            value={formData.sujet}
            onChange={handleChange}
            required
          />

          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Votre email"
            value={formData.email}
            onChange={handleChange}
            required
          />

          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            name="message"
            placeholder="Décrivez votre projet"
            style={{ height: '200px' }}
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>

          <input type="submit" value="Envoyer" />
          <p className='politique-donnée'>Les informations fournies ne seront utilisées que dans le cadre de ce contact et ne seront en aucun cas partagées avec des tiers.</p>
        </form>
      )}
    </div>
  );
}

export default ContactForm;
