import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import '../FAQ/faq.css'
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  marginBottom: '25px',
  border: `2px solid ${theme.palette.divider}`,
  borderRadius: '5px', 
  '&:not(:last-child)': {
    
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: '#fff' }} />} 
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: '#182138',
  color: '#fff',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '2px solid rgba(255, 255, 255,255)',
  color: '#fff',
  backgroundColor: '#182138',
  
}));

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div id='FAQ' className='accordion-main'>
    <div className='Title-faq'>
    <i className="fa-solid fa-comment-dots sizelogo"></i>
      <h2>Questions fréquentes:</h2>
    </div>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>Je dispose de quelques concepts pour mon site, mais je peine à les concrétiser.</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Beaucoup de gens se retrouvent dans cette situation. Il est essentiel de clarifier vos idées et de les rendre tangibles avant de vous lancer dans le développement de votre site. Je suis là pour vous aider à donner vie à vos concepts et à créer un site web qui reflète fidèlement votre vision et votre identité en ligne.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>Quelles sont les options de suivi et de maintenance disponibles une fois que mon site est livré ? </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Une fois que votre site est livré, vous avez la possibilité de bénéficier d'un suivi continu en choisissant parmi différentes options. Je reste à votre disposition pour tout ajustement, ajout de fonctionnalités ou assistance technique selon vos besoins. Mon objectif est de vous offrir un service personnalisé pour que votre site reste à jour et performant.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Est-il possible de travailler ensemble à distance ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Nous pouvons collaborer à distance sans aucun problème. Peu importe où vous vous trouvez, que ce soit en France ou à l’étranger, il vous suffira de convenir d'un rendez-vous virtuel pour commencer notre collaboration.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
