import React from 'react';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import Projetcard from './ProjectCard/ProjectCard'

function Projet() {
  return (
   <>
   <Header/>
   <div className='Projet-container-main'> 
     <Projetcard/> 
    </div>
    <Footer/>
    </>
  );
}

export default Projet;