import React from "react";
import '../Header/header.css'
import { Link } from 'react-router-dom';

const Header = () => {
    return (
    <header>
      <div className='Header-container'>
        <div className="header-logo">
          <Link to='/'><h1><i className="fa-solid fa-bolt fa-flip"></i>BenoitDx</h1></Link>
        </div>
        <nav>
          <ul>
            <li>
            <Link to='/' activeclassname='active'>Acceuil </Link>   
            </li>
            <li>
            <a href="/#Prestation" activeclassname="active">Prestation proposées</a>
            </li>
            <li>
            <a href="/#FAQ" activeclassname="active">F.A.Q.</a>
            </li>
            <li>
            <Link to="/projet">Mes projets</Link>
            </li>
            
            
          </ul>
        </nav>
        <nav>
        <li className="cercletip">
            <Link to="/contact" className="contact-button" activeclassname="active">Contactez-moi</Link>
            </li>
        </nav>
      </div>    
    </header>
    )
}

export default Header;