import React from 'react';
import Header from '../components/Header/Header';
import DeveloperPresentation from '../components/Welcomme/Welcomme';
import InfoServices from '../components/InfoServices/InfoServices';
import Prestation from '../components/Prestation/Prestation';
import AccordionUsage from '../components/FAQ/FAQ';


import Footer from '../components/Footer/Footer';
import '../index.css';

function HomePage() {
  return (
    <>
      <Header />
      <DeveloperPresentation />
      <InfoServices />
      <Prestation />
      <AccordionUsage />
      <Footer />
    </>
  );
}

export default HomePage;
