import React from 'react';

const PolitiqueConfidentialite = () => {
  return (
    <div className='Mentioncolor'>
      <h2>Politique de Confidentialité</h2>

      <h3>Collecte et Utilisation des Informations</h3>
      <p>Lorsque vous remplissez un formulaire de contact sur notre site web, nous collectons vos informations personnelles telles que votre nom, votre adresse e-mail et votre numéro de téléphone. Ces informations sont collectées de manière transparente et utilisées uniquement pour vous contacter concernant vos demandes de services et pour la réalisation de projets.</p>

      <h4>Identité et Coordonnées de l'Organisme Responsable du Traitement Informatique des Données Personnelles :</h4>
      <p>BenoitDx, représenté par BERCHE Benoit, contactable à l'adresse 80 rue Marie Curie, 62118 Biach St Vaast, France, et par e-mail à contact@benoitdx.com.</p>

      <h4>Finalité Poursuivie par le Traitement :</h4>
      <p>Les données personnelles collectées sont utilisées dans le but de vous contacter et de répondre à vos demandes de services.</p>

      <h4>Base Légale Justifiant le Traitement :</h4>
      <p>Le traitement de vos données personnelles est basé sur votre consentement explicite lorsque vous remplissez le formulaire de contact.</p>

      <h4>Caractère Obligatoire ou Facultatif du Recueil de Données Personnelles :</h4>
      <p>Le recueil de vos données personnelles est facultatif, cependant, si vous choisissez de ne pas les fournir, nous pourrions ne pas être en mesure de répondre à vos demandes de services.</p>

      <h4>Destinataires des Données Personnelles :</h4>
      <p>Les données personnelles collectées ne sont accessibles qu'au personnel autorisé de BenoitDx et ne sont pas partagées avec des tiers, sauf si cela est nécessaire pour répondre à une demande de votre part ou pour se conformer à la loi.</p>

      <h4>Durée de Conservation des Données Personnelles :</h4>
      <p>Nous conservons vos informations personnelles aussi longtemps que nécessaire pour vous fournir les services demandés et pour respecter nos obligations légales. Par défaut, le consentement est donné pour 13 mois à partir de la date du recueil. Si vous souhaitez que vos données soient supprimées, veuillez nous contacter à l'adresse contact@benoitdx.com.</p>

      <h4>Droits de l'Internaute :</h4>
      <p>Vous avez le droit de refuser la collecte de vos données personnelles, ainsi que le droit d'accéder, de rectifier et d'effacer vos données personnelles. Vous pouvez exercer ces droits en nous contactant à l'adresse contact@benoitdx.com.</p>

      <h4>Droit de l'Internaute d'Introduire une Réclamation auprès de la CNIL :</h4>
      <p>Vous avez le droit d'introduire une réclamation auprès de la Commission nationale de l'informatique et des libertés (CNIL) si vous estimez que le traitement de vos données personnelles ne respecte pas la législation en vigueur.</p>

      <h3>Absence de Cookies</h3>
      <p>Nous tenons à préciser que notre site web n'utilise pas de cookies.</p>

      <h3>Liens Vers d'Autres Sites Web</h3>
      <p>Notre site web peut contenir des liens vers d'autres sites web qui ne sont pas exploités par nous. Veuillez noter que nous n'avons aucun contrôle sur le contenu et les pratiques de ces sites, et nous déclinons toute responsabilité quant à leur contenu ou à leurs pratiques de confidentialité.</p>

      <h3>Hébergeur du Site</h3>
      <p>Le site https://benoitdx.com est hébergé par O2switch, dont l'adresse est Chem. des Pardiaux, 63000 Clermont-Ferrand, France.</p>

      <h3>Consentement</h3>
      <p>En utilisant ce site web, vous consentez à notre politique de confidentialité.</p>

      <p>Si vous avez des questions ou des préoccupations concernant notre politique de confidentialité, veuillez nous contacter à l'adresse contact@benoitdx.com.</p>
    </div>
  );
}

export default PolitiqueConfidentialite;