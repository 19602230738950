import React from 'react';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import { Link } from 'react-router-dom';

function NotFoundPage() {
  return (
   <>
   <Header/>
   <div className='Error-container'> 
      <h1 className='Error Title'>404 - Page non trouvée</h1>
      <p>Désolé, la page que vous recherchez n'existe pas.</p>
      <Link to='/'><h1><i class="fa-solid fa-bolt fa-flip"></i>Retournez à l'accueil</h1></Link>
    </div>
    <Footer/>
    </>
  );
}

export default NotFoundPage;