import React, { useState } from 'react';
import projet1 from '../../assets/images/projet1.webp';
import projet2 from '../../assets/images/projet2.webp';
import projet3 from '../../assets/images/projet3.webp';
import projet4 from '../../assets/images/projet4.webp';
import '../ProjectCard/projectcard.css'

const projects = [
    {
        id: 1,
        title: 'Booki',
        image: projet1,
        description: "En tant qu'étudiant, j'ai eu l'opportunité de travailler sur un projet fictif passionnant. Mon objectif était de concevoir un site web innovant permettant aux utilisateurs de rechercher des hébergements et des activités dans la ville de leur choix. Cette expérience m'a permis de mettre en pratique mes compétences en intégration HTML/CSS et de collaborer avec une équipe virtuelle pour créer une interface utilisateur conviviale.",
        githubLink: 'https://github.com/BenoitDx/P2OC', 
    },
    {
        id: 2,
        title: 'Sophie Bluel',
        image: projet2,
        description: "En tant qu'étudiant, j'ai eu l'opportunité de contribuer en tant que développeur front-end à la conception du site portfolio d'une architecte d'intérieur. Ma mission consistait à développer plusieurs fonctionnalités clés, notamment la page de présentation des travaux de l'architecte, la page de connexion de l'administrateur du site et une modale permettant d'uploader de nouveaux médias. J'ai pu utiliser mes compétences en gestion d'événements utilisateur, manipulation du DOM et récupération de données utilisateur via des formulaires pour mener à bien ces tâches. ",
        githubLink: 'lien-github-projet1',
    },
    {
        id: 3,
        title: 'Nina Carducci',
        image: projet3,
        description: " J'ai effectué une optimisation globale du site en améliorant les performances et le référencement. J'ai mis en place le référencement local en utilisant Schema.org, ajouté des métadonnées pour les réseaux sociaux, résolu des problèmes de code et amélioré l'accessibilité.",
        githubLink: 'https://github.com/BenoitDx/P5OC',
    },
    {
        id: 4,
        title: 'Kasa',
        image: projet4,
        description: "J'ai optimisé le site web d'une photographe en améliorant les performances, en réduisant le temps de chargement, et en fournissant un rapport détaillé de mes actions.j'ai aussi participé à la refonte complète du site web de Kasa en utilisant React et NodeJS, en suivant les maquettes Figma, et en respectant les contraintes techniques et fonctionnelles.",
        githubLink: 'https://github.com/BenoitDx/P6OC',
    },
];

function ProjectCard() {
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);

    const openModal = (project) => {
        setSelectedProject(project);
        setModalVisible(true);
    };

    const closeModal = () => {
        setSelectedProject(null);
        setModalVisible(false);
    };

    return (
        <div id='projets' className="project-card-container">
            <h2 className='title-project-card'>Mes projets</h2>
            <div className="project-grid">  
                {projects.map((project) => (
                    <div key={project.id} className="project-item">
                        <img
                            src={project.image}
                            alt={project.title}
                            onClick={() => openModal(project)}
                            className="project-image"
                        />
                        <div className="project-title">{project.title}</div>
                    </div>
                ))}
            </div>

            {modalVisible && selectedProject && (
    <div className="project-modal" onClick={closeModal}>
        <div className="modalproject-content">
            <h2>{selectedProject.title}</h2>
            <img
                src={selectedProject.image}
                alt={selectedProject.title}
                className="modal-project-image"
            />
            <p>{selectedProject.description}</p>
            <a className='lienproject'
                href={selectedProject.githubLink}
                target="_blank"
                rel="noopener noreferrer"
            >
                Voir le projet sur GitHub
            </a>
        </div>
    </div>
)}
        </div>
    );
}

export default ProjectCard;
