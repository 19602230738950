import React from 'react';
import '../InfoServices/Infoservices.css'
const ServicesSection = () => {
  return (
    <div className='elementor-service'>
      
      <div className='elementor-service-cat' >
        <i className="fa-regular fa-comment "></i>
        <h3>Développement sur mesure</h3>
        <p>Des solutions personnalisées qui répondent à vos besoins uniques.</p>
      </div>
      <div className='elementor-service-cat' >
        <i className="fa-solid fa-mobile-screen "></i>
        
        <h3>Responsive design</h3>
        <p>Des sites web priorisant le mobile qui s'affichent parfaitement sur n'importe quel appareil</p>
      </div>
      <div className='elementor-service-cat' >
          <i className="fa-solid fa-spinner "></i>
        <h3>Optimisation des performances</h3>
        <p>Accélérez votre site grâce aux dernières techniques d'optimisation</p>
      </div>
      <div className='elementor-service-cat' >
        <i className="fa-solid fa-wrench"></i>
        <h3>Support continu</h3>
        <p>Maintenance fiable pour une tranquillité d'esprit après le lancement</p>
      </div>
    </div>
  );
};

export default ServicesSection;
