import React from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import '../index.css';
import PolitiqueConfidentialite from './Politique/Politique';

function Politique() {
  return (
    <>
      <Header />
      <PolitiqueConfidentialite/>
      
     
      
      
      <Footer />
    </>
  );
}

export default Politique;
