import React from 'react';
import '../Prestation/prestation.css';
import { Link } from 'react-router-dom';

const About = () => {
    return (
        <section id='Prestation' className="Prestation-Section">
          <div className='info-prestation'>
            <i className="fa-regular fa-comments sizeicicone"></i>
              <p>N'hésitez pas à me contacter pour discuter de projets passionnants ou de collaborations potentielles.</p>
              {/* Lien vers la page de contact */}
              <Link to="/contact">
                <button className='button-contact'>Me contacter</button>
              </Link>
            </div>
            <h2>Prestations proposées</h2>
            <div className='services-wrapper'>
                <div className='elementor-service-cat' >
                    <i className="fa-solid fa-globe sizeicicone"></i>
                    <h3>Création de Sites Vitrine</h3>
                    <p>Conception personnalisée selon vos besoins.</p>
                    <p> Interface utilisateur intuitive et moderne.</p>
                    <p>  Optimisation pour tous les appareils (responsive design).</p>
                    <p>Pour plus de détails, n'hésitez pas à me contacter.</p>
                    <p>Á partir de: <strong>899€</strong></p>
                    {/* Lien vers la page de contact */}
                    <Link to="/contact">
                      <button className='button-pos'>Devis-Gratuit</button>
                    </Link>
                </div>
                <div className='elementor-service-cat' >
                    <i className="fa-solid fa-money-check-dollar sizeicicone"></i>
                    <h3>Sites E-commerce</h3>
                    <p>Développement de boutiques en ligne sécurisées.</p>
                    <p>Gestion des produits, des commandes et des paiements.</p>
                    <p>Intégration de systèmes de paiement sécurisés.</p>
                    <p>Pour plus de détails, n'hésitez pas à me contacter.</p>
                    <p>Á partir de: <strong>1899€</strong></p>
                    {/* Lien vers la page de contact */}
                    <Link to="/contact">
                      <button className='button-pos'>Devis-Gratuit</button>
                    </Link>
                </div>
                <div className='elementor-service-cat' >
                    <i className="fa-solid fa-rocket sizeicicone"></i>
                    <h3>Référencement (SEO)</h3>
                    <p>Optimisation du contenu pour les moteurs de recherche.</p>
                    <p>Amélioration de la visibilité et du classement de recherche.</p>
                    <p>Stratégies de marketing de contenu et de création de liens.</p>
                    <p>Pour plus de détails, n'hésitez pas à me contacter.</p>
                    <p>Á partir de: <strong>399€</strong></p>
                    {/* Lien vers la page de contact */}
                    <Link to="/contact">
                      <button className='button-pos'>Devis-Gratuit</button>
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default About;
